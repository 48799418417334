// React
import React from 'react'

// Image
import { useRouter } from 'next/router'
import Image from 'next/image'
import Head from 'next/head'

// Components
import { LoginForm } from '@/components/blocks/login-form'

// Utils
import { useSession } from '@/utils/auth/user-context'
import { getLandingStaticProps } from '@/utils/ui/landing/get-static-props'

// External
import { useTranslation } from 'next-i18next'

const LoginPage: React.FC = () => {
  const router = useRouter()
  const session = useSession()
  const { t } = useTranslation('routes/login')

  if (session) {
    router.replace(
      router.query.redirect ? String(router.query.redirect) : '/text-to-meme'
    )
    return null
  }

  return (
    <div className='flex min-h-screen flex-col items-center md:flex-row-reverse'>
      <Head>
        <title>{t('meta.title')}</title>
        <meta name='description' content={t('meta.description')} />
        <meta name='robots' content='noindex' />
        <meta property='og:url' content='https://supermeme.ai/login' />
        <link rel='canonical' href='https://supermeme.ai/login' />
      </Head>
      <div className='flex w-full flex-1 items-center justify-center p-0 md:p-8'>
        <LoginForm isDialog={false} />
      </div>
      <div className='flex w-full flex-1 items-center justify-center p-8'>
        <Image
          alt={t('image.alt')}
          src={'/static/login.png'}
          height={476}
          width={612.5}
        />
      </div>
    </div>
  )
}

export const getStaticProps = async ({ locale }: { locale: string }) =>
  getLandingStaticProps({
    locale,
    namespaces: ['common', 'routes/login', 'components/login-form'],
  })

export default LoginPage
